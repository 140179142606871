<template>
    <router-view />
    <Loading />
    <ErrorModal />
</template>

<script>
import Loading from '@/containers/Loading.vue'
import ErrorModal from '@/containers/ErrorModal.vue'

export default {
    name: 'FullSizeLayout',
    components: {
        Loading,
        ErrorModal
    },
    created() {
        this.$hideLoading()
    }
}
</script>

<style lang="scss" scoped></style>
